<template>
  <div class="bg-grey-stripo">
    <PopUpPreview
      ref="previewPopup"
      :htmlContent="emailHtml"
      :isPdpa="pdpaEmail"
    ></PopUpPreview>

    <div id="stripoSettingsContainer">Loading...</div>
    <div id="stripoPreviewContainer"></div>
  </div>
</template>

<script>
import ExternalDisplayConditionsPopup from "./external-popup.js";
import PopUpPreview from "../automation/component/PopUpPreviw.vue";
export default {
  components: {
    PopUpPreview,
  },
  mounted() {
    this.loadDemoTemplate(this.initPlugin);
  },
  // Utility methods
  props: {
    value: {
      required: false,
      type: String,
    },
    textFloat: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    variableItem: {
      required: false,
    },
    formMain: {
      required: false,
    },
    template: {
      required: false,
      type: Object,
      default() {
        return {
          html: "",
          css: "",
        };
      },
    },
    pdpaEmail: {
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      file: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
        excel: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
      error: "",
      hasError: false,
      hasImage: false,
      imgModal: null,
      msgModal: null,
      isSuccess: false,
      showPreview: false,
      emailHtml: "",
    };
  },
  methods: {
    save() {
      window.StripoApi.getTemplate(function (html, css) {});
    },
    openPreviewPopup() {
      window.StripoApi.compileEmail((error, html) => {
        if (!error) {
          this.emailHtml = html;
          this.$refs.previewPopup.openPopup();
        } else {
          console.error("Error compiling email for preview:", error);
        }
      }, true);
    },
    async compileEmail() {
      this.$bus.$emit("showLoading");
      return new Promise((resolve, reject) => {
        const $ = this;

        return window.StripoApi.compileEmail(
          function (error, compile) {
            let compileHtml = compile;
            window.StripoApi.getTemplate(function (html, css) {
              let template = { html, css };
              $.$emit("onDataChange", { compileHtml, template });
              $.$bus.$emit("hideLoading");
              return resolve({ compileHtml, template });
            });
          },
          true,
          false
        );
      });
    },
    async loadDemoTemplate(callback) {
      const $ = this;
      if (!$.template)
        this.request(
          "GET",
          "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.html",
          null,
          function (html) {
            $.request(
              "GET",
              "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.css",
              null,
              function (css) {
                callback({
                  html: html,
                  css: css,
                });
              }
            );
          }
        );
      else
        callback({
          html: $.template.html,
          css: $.template.css,
        });
    },
    request(method, url, data, callback) {
      var req = new XMLHttpRequest();
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          callback(req.responseText);
        } else if (req.readyState === 4 && req.status !== 200) {
          console.error(
            "Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values"
          );
        }
      };
      req.open(method, url, true);
      if (method !== "GET") {
        req.setRequestHeader("content-type", "application/json");
      }
      req.send(data);
    },

    initPlugin(template) {
      const apiRequestData = {
        emailId: "6251361f166bfd9e51e19a03",
        userId: "600a68cbbbc06742e4453517",
        groupId: "test",
      };
      const script = document.createElement("script");
      script.id = "stripoScript";
      script.type = "text/javascript";
      script.src = "https://plugins.stripo.email/static/latest/stripo.js";
      var $ = this;
      script.onload = function () {
        window.Stripo.init({
          settingsId: "stripoSettingsContainer",
          previewId: "stripoPreviewContainer",
          // codeEditorButtonId: "codeEditor",
          // emailPreviewButtonId: "codeEditor",
          locale: "en",
          html: template.html,
          css: template.css,
          apiRequestData: apiRequestData,
          userFullName: "Plugin Demo User",
          mergeTags: [
            {
              category: "MailChimp",
              entries: $.variableItem,
            },
          ],

          getAuthToken: function (callback) {
            $.request(
              "POST",
              "https://plugins.stripo.email/api/v1/auth",
              JSON.stringify({
                pluginId: "2d866fa0fd8e43dbaff4ffd219c09531",
                secretKey: "60faabfe0dcb43a7bfe016cbd7568830",
              }),
              function (data) {
                callback(JSON.parse(data).token);
              }
            );
          },
        });
      };
      document.body.appendChild(script);
    },
    clearField() {
      const $ = this;
      this.request(
        "GET",
        "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.html",
        null,
        function (html) {
          $.request(
            "GET",
            "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.css",
            null,
            async function (css) {
              await $.initPlugin({
                html: html,
                css: css,
              });
              $.compileEmail();
            }
          );
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.nav-tabs.nav-justified {
  display: block !important;
}
#stripoSettingsContainer {
  width: 400px;
  float: left;
  height: 100% !important;
  max-height: 100% !important;
  overflow: auto;

  // ::v-deep buttons-selector {
  //   display: none !important;
  // }

  ::v-deep .caret {
    display: none;
  }

  ::v-deep .upload-link {
    width: 300px;
  }

  ::v-deep .esdev-app .dropdown-menu-right {
    right: auto !important;
  }

  ::v-deep .esdev-t-upload-menu {
    width: 40px;
  }

  ::v-deep .esdev-library-thumbnails-container {
    overflow-y: auto !important;
  }

  ::v-deep .modules-category {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: 150px !important;
  }
  ::v-deep .esdev-item-name {
    max-height: unset !important;
    height: 20px !important;
  }
}

#stripoPreviewContainer {
  // width: auto;
  min-width: calc(100% - 400px);
  float: left;
}

::v-deep .esdev-preview-splitter {
  // height: auto !important;
}
</style>
